.footer {
 
  text-align: center;
  color: rgb(166, 2, 166);
  text-shadow: 0 0 0.2em rgb(255, 255, 255);
  background-color: "rgb(252, 181, 0)";
  display: flex;
  align-items: center;
  justify-content: center;
  
}
