/* Notification.css */
.search-notification {
  position: fixed;
  top: 20px;
  right: 20px;
  padding: 15px 25px;
  border-radius: 5px;
  color: #fff;
  z-index: 1000;
  opacity: 0.9;
  transition: opacity 0.3s ease;
}

.search-notification.success {
  background-color: #4caf50; /* Green */
}

.search-notification.error {
  background-color: #f44336; /* Red */
}
