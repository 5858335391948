/* Example CSS to ensure map container occupies full space */
.map-container {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  background: black;
}
.user-marker {
  width: 20px;
  height: 20px;
  background-color: #007cbf;
  border: 2px solid white;
  border-radius: 50%;
} 
#opacity-control {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
  background: white;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  font-family: Arial, sans-serif;
  font-size: 14px;
}
#opacity-slider {
  position: absolute;
  top: 10px;
  left: 10px;
  background: rgba(255, 255, 255, 0.9);
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  z-index: 1000;
}


/* MapComponent.css */

/* Import CSS của maplibre-gl */
@import 'maplibre-gl/dist/maplibre-gl.css';

/* Tùy chỉnh các lớp control nếu cần */
.maplibregl-ctrl-geolocate {
 
  background-size: 20px 20px;
  width: 30px;
  height: 30px;
  border: none;
  cursor: pointer;
  outline: none;
 display: none !important;
}

.maplibregl-ctrl-geolocate.active {
  background-color: #e0e0e0; /* Thay đổi màu nền khi đang theo dõi */
}

/* Ẩn GeolocateControl */
 
 
 
 
.mapbox-gl-draw_polygon {
    display: none !important;
}

.mapbox-gl-draw_line {
    display: none !important;
}
 
.mapbox-gl-draw_trash {
    display: none !important;
}
