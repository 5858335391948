/* InfoPanel.css */

/* Mặc định dành cho Desktop */
.info-panel {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 400px;
  background-color: rgb(255, 255, 255);
  box-shadow: 2px 0 10px rgba(0, 0, 0, 0.3);
  border-right: 1px solid #ffffff;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  transform: translateX(-100%);
  opacity: 0;
  z-index: 1001;
  overflow-y: auto;
}

.info-panel.visible {
  transform: translateX(0);
  opacity: 1;
}

.info-panel.hidden {
  transform: translateX(-100%);
  opacity: 0;
}

.feature-details,
.additional-details {
  display: flex;
  flex-wrap: wrap;
}

.feature-details dt,
.additional-details dt {
  width: 40%;
  font-weight: bold;
  margin-top: 10px;
}

.feature-details dd,
.additional-details dd {
  width: 60%;
  margin-bottom: 10px;
}

.additional-info {
  margin-top: 20px;
}

.additional-info h3 {
  margin-bottom: 10px;
}

.custom-children {
  margin-top: 20px;
}

/* Media Query dành cho Mobile (max-width: 768px) */
@media (max-width: 768px) {
  .info-panel {
    top: auto;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 270 !important;
    transform: translateY(100%);
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.3);
    border-top: 1px solid #ffffff;
  }

  .info-panel.visible {
    transform: translateY(0);
  }

  .info-panel.hidden {
    transform: translateY(100%);
  }
}

.action-buttons {
  display: flex;
  gap: 10px;
}

.action-button {
  font-size: 14px;
  cursor: pointer;
  background-color: #0022ff;
  color: white;
  border: none;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.action-button:hover {
  background-color: #248f1f;
}

.info-tab-bar {
  position: sticky; /* Giữ cố định tab bar khi cuộn */
  top: 0; /* Dính vào cạnh trên */
  z-index: 1000; /* Hiển thị trên các phần tử khác */
  display: flex;
  align-items: center;
  background-color: none; /* Đặt màu nền để không bị chồng lẫn */
  white-space: nowrap;
  overflow: hidden; /* Ẩn các phần tử tràn ra ngoài */
  box-shadow: 0px 2px 5px rgba(255, 0, 0, 0.1); /* Hiệu ứng đổ bóng */
  border-bottom: none; /* Loại bỏ đường viền đen bên dưới */
}


.scrollable-buttons {
  display: flex;
  gap: 10px;
  overflow-x: auto; /* Cho phép cuộn ngang nếu nút vượt quá chiều rộng */
  flex-grow: 1;
  white-space: nowrap; /* Ngăn các nút xuống dòng */
}

.scrollable-buttons::-webkit-scrollbar {
  display: none; /* Ẩn thanh cuộn ngang */
}

.close-button {
  flex-shrink: 0;
  margin-left: 10px;
  cursor: pointer;
  color: red;
}





.info-key {
  background: none;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  color: #0022ff;
  margin-left: auto;
  font-weight: bold;
}


