.mapContainer {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

  
  
 
  
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
}




::-webkit-scrollbar-thumb {
background-color: #88888800; /* Màu của con trỏ thanh cuộn */
border-radius: 5px; /* Độ cong của con trỏ */
}




input[type="range"] {
  position: absolute;
  transform: rotate(-90deg);
  bottom: 16%;
  right: -1.5em;
  width: 120px;
  height: 10px;
  margin: 0;
  border: none;
  padding: 1px 2px;
  border-radius: 30px;
  background: #f1f0ee;
  outline: 0;
  transition: background-color 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
}

input[type="range"]:hover {
  transform: rotate(-90deg) translateY(-3px); /* Di chuyển lên khi hover */
}


input[type="range"]:active,
input[type="range"]:focus {
  background: rgb(255, 170, 0); /* Mờ đi */
}

 

input[type="range"]::-webkit-slider-thumb {
  transition: transform 0.3s ease-in-out; /* Thêm hiệu ứng chuyển động */
  transform: rotate(-90deg) translateY(0) scale(2); /* Kích thước ban đầu */
  color: #ffe100;
}

input[type="range"]:hover::-webkit-slider-thumb {
  transform: rotate(-90deg) translateY(0) scale(2.7); /* Kích thước phóng to khi hover */
}


ul {
  list-style: none;
}

li::after {
  content: "";
  display: block;
  height: 0px;
  transition: height 0.3s ease-in-out;
  pointer-events: none;
}

li:hover::after {
  height: 10px;
}

.example-2 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.example-2 .icon-content {
  margin: 0 10px;
  position: relative;
}
.example-2 .icon-content .tooltip {
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  padding: 6px 10px;
  border-radius: 5px;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  font-size: 14px;
  transition: all 0.3s ease;
}
.example-2 .icon-content:hover .tooltip {
  opacity: 1;
  visibility: visible;
  bottom: -40px;
}
.example-2 .icon-content a {
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  color: #4d4d4d;
  background-color: #fff;
  transition: all 0.3s ease-in-out;
}
.example-2 .icon-content a:hover {
  box-shadow: 3px 2px 45px 0px rgb(0 0 0 / 12%);
}
.example-2 .icon-content a svg {
  position: relative;
  z-index: 1;
  width: 30px;
  height: 30px;
}
.example-2 .icon-content a:hover {
  color: white;
}
.example-2 .icon-content a .filled {
  position: absolute;
  top: auto;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: #000;
  transition: all 0.3s ease-in-out;
}
.example-2 .icon-content a:hover .filled {
  height: 100%;
}
.example-2 .icon-content a[data-social="spotify"] .filled,
.example-2 .icon-content a[data-social="spotify"] ~ .tooltip {
  background-color: #1db954;
}
.example-2 .icon-content a[data-social="pinterest"] .filled,
.example-2 .icon-content a[data-social="pinterest"] ~ .tooltip {
  background-color: #bd081c;
}
.example-2 .icon-content a[data-social="dribbble"] .filled,
.example-2 .icon-content a[data-social="dribbble"] ~ .tooltip {
  background-color: #ea4c89;
}
.example-2 .icon-content a[data-social="telegram"] .filled,
.example-2 .icon-content a[data-social="telegram"] ~ .tooltip {
  background-color: #0088cc;
}



