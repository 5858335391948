/* src/components/SearchBar.css */
.search-container {
  position: absolute;
  top: -20;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  background:none;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  display: flex;
  gap: 10px;
  align-items: center;
}

.search-container input {
  padding: 5px;
  font-size: 14px;
  width: 80px;
  border: 1px solid none;
  border-radius: 3px;
}

.search-container button {
  padding: 6px 12px;
  font-size: 14px;
  border: none;
  background-color: #3887be;
  color: white;
  border-radius: 3px;
  cursor: pointer;
}

.search-container button:hover {
  background-color: #3074a5;
}
/* src/components/Notification.css */
.search-notification {
  position: fixed;
  top: 20px;
  right: 20px;
  padding: 15px 20px;
  border-radius: 5px;
  font-family: Arial, sans-serif;
  z-index: 1000;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  opacity: 0.95;
}

.search-notification.success {
  background-color: #4caf50; /* Green */
  color: white;
}

.search-notification.error {
  background-color: #f44336; /* Red */
  color: white;
}
