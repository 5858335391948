@import url("https://fonts.googleapis.com/css2?family=Tajawal:wght@200&display=swap");
.link {
  color: #000;
}

.news-item {
  background-color: rgb(243, 243, 243);
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  background-color: rgba(193, 26, 193, 0.384); */
  height: 100%;
  width: 100%;
  overflow: hidden;
  font-family: "SF Medium", Calibri, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
}


.news-item:first-child {
  height: 100%;
  width: 100%;
  grid-column: 1 / span 3;
  position: relative;
}

/* Your existing styles */
/* Your existing styles */

/* Media query for screens with a maximum width of 480px (adjust as needed) */
@media screen and (max-width: 480px) {
  .news-item {
    grid-column: span 2; /* On mobile, all items span 2 columns */
  }

  .news-item:first-child {
    grid-column: span 3; /* On mobile, the first item spans 3 columns */
  }
}


.news-item:first-child .news-item__image img {
  height: 100vw;
  width: 100vw;
  flex: 100%;
}

.news-item:first-child::after {
  content: "Tin Mới";
  position: absolute;
  top: 0;
  left: 0;
  width: 20%;
  height: 20px;
  background-color: red;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius:5px;
  box-shadow: rgb(255, 0, 0) 3px 3px 6px 0px inset,
    rgba(148, 133, 133, 0.5) -3px -3px 6px 1px inset;
  animation: blink 1s infinite alternate; /* Blinking animation */
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.news-item .news-item__image {
  width: auto;
  height: auto;
  overflow: hidden;
  grid-column: 1 / span 1;
  border-radius: 5px;
}

.news-item img {
  
  width: 100%;
  height: 100%;
  transition: all 1s;
}

.news-item img:hover {
  transform: scale(1.4);
  grid-column: 1 / span 3;
}

.news-item h2 {
  padding: 0.5rem;
  font-family: "SF Medium", sans-serif;
}

.news-item p {
  padding: 0 0.5rem;
}

 
@media screen and (max-width: 480px) {
  .news-item:first-child {
    grid-template-columns: auto;
  }

  .news-item .news-item__image {
    grid-column: 1 / span 2; /* Adjust the grid columns to take two columns */
  }
}

