
.disable-multitouch {
  touch-action: none;
}

.container {
  position: relative;
}
 

 
html,
body,

 
#root,
.App {
  overflow-wrap: break-word;
  background-color: rgb(255, 200, 0);
  width: 100%;
  height: 100%;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  text-align: center;
  background: rgb(94,46,134);
  /* background: url("/public/assets/icons/sky.jpg")  ; */
  background: url("/public/assets/icons/photo_2024-04-14_10-27-22.jpg")  ;
  background-size: cover;
 background-repeat: no-repeat;
 background-position:center ;
 background-attachment:fixed ; 
 animation-name: move-background;
 animation-duration: 30s;
 animation-timing-function: linear;
 animation-iteration-count: infinite;
 animation-direction: alternate;
 overflow: hidden;
 /* touch-action: none; */
}

 


.zoomed-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
 
}

.zoomed-image {
  max-width: 100%;
  max-height: 100%;
  cursor: zoom-out;
}

.image-container:hover {
  transform: scale(1.1); /* Optional: add zoom effect on hover */
  transition: transform 0.5s ease;
}


 
.image-container {
  position: relative;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%; /* hoặc giá trị khác phù hợp */
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
display: none;
}




img {
display: block;
max-width: 100%;
}

/* CarouselStyles.css */
@keyframes slideInRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideOutLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.carousel-item {
  animation: slideInRight 1s forwards;
}

.carousel-item-prev.carousel-item-left,
.carousel-item-next.carousel-item-right {
  animation: slideOutLeft 1s forwards;
}

.old-price {
  line-height: 1.375;
  font-size: 16px; /* Adjust the font size as needed */
  font-weight: bold;
  padding: 0 0 0 4px;
  text-align: center;
  color: #0044ff;
  text-shadow:   0 1.5px #fff, 0 -2px #fff, 1px 1px #fff, -1px -1px #fff, 1px -1px #fff, -2px 0.5px #fff;
}