.news-list {
  display: grid;
  grid-template-columns: repeat(3,3fr); /* One column for mobile */
  gap: 1rem;
  margin: 2rem 0;
  min-height: calc(100vh - 200px - 0.5rem);
  top:160;
}

.news-list .no-news {
  text-align: center;
  width: 100%;
}

@media screen and (max-width: 480px) {
  .news-list {
    display: flex;
    flex-direction: column;
  }
}


 

.main-container {
  margin-top: 60px; /* Adjust this value based on the height of your search bar */
}
 
/* Media query for mobile devices (up to 767px) */
@media (max-width: 767px) {
  .main-container {
    margin-top: 0; /* Reset margin for mobile */
  }
}
