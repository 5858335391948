 
.title {
  width: 100%;
  text-align: center;
  background: rgba(23, 23, 225, 0);
  border-radius: 10px;
  font-size: 12px;
  font-weight: bold;
  font-style: italic;

  
  
  
}


.offCanvas {
  background: none !important;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  height: 300 !important;
  border: none !important;
  border-radius: 20px;
  padding-right: 20px;
  padding:10px ;



}

/* Thiết lập mặc định cho .infoWrapper */
.infoWrapper {
  background: rgb(255, 255, 255);
  height: 500px;
  overflow: auto;
  border-radius: 10px;
  margin-top: 10;
}

/* Thiết lập cho các thiết bị di động */
@media only screen and (max-width: 768px) {
  .infoWrapper {
    width: 105%;
  }
}

/* Thiết lập cho desktop */
@media only screen and (min-width: 481px) {
  .infoWrapper {
    width: 50%;
  }
}
