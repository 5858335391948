.news-page {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  margin: 2rem 0;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  height: 100%;
  align-items: center;
 
}

 
.news-page .news-page__image {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin: 2rem 0;
  
}

/* Điều chỉnh kích thước trên desktop */
@media screen and (min-width: 768px) {
  .news-page .news-page__image {
    flex-wrap: wrap;
  }

  .news-page .news-page__image img {
    flex: 1 1 25%; /* Chia đều thành 4 cột trên desktop */
    max-width: 55%; /* Kích thước ảnh giữa 25% trên desktop */
    width: auto;
    height: auto; 
  }
}

/* Kích thước của ảnh trên mobile */
@media screen and (max-width: 767px) {
  .news-page .news-page__image img {
    width: 100%; /* Kích thước ảnh giữa 100% trên mobile */
    width: auto;
    height: auto; 
  }
}











 
/* Điều chỉnh kích thước trên desktop */
@media screen and (min-width: 768px) {
  .news-page1 {
    flex: 0 0 40%; /* Kích thước ảnh giữa 40% trên desktop */
  }
}

 


.news-page__title {
  /* margin: 1rem 0; */
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20px;
  font-family: "SF Medium";
  width: auto;
  height: auto; 
  font-size: 16px;
  transition: color 0.2s;
  margin-bottom: 5px;
  margin: 0 0 10px;
  color: rgb(209, 193, 193);
  /* font-weight: 600; */
  height: 60px;
  max-width: fit-content;
  
  overflow: hidden;
  font-size: 15px;
  color: #4a4a4a;
  text-align: justify;
  margin-bottom: 15px;
}
.news-page__title:hover {
  color: red;
}
.news-page__description {
  /* font-size: 1.2rem;
  font-family: sans-serif; */
  text-align: auto;
  font-size: 13px;
  color: #070606;
  font-family: "SF Regular";
  padding: 0 5px 0 0;
  line-height: 19px;
  margin: 15px;
  overflow: scroll;
  text-overflow: ellipsis;
  max-height: fixed;
  margin-bottom: 15px;
  max-width: fit-content;
}

.news-page img {
   width: auto;
  height: auto; 
  border-radius: 5px;
  vertical-align: middle;
}

.news-page__date {
  margin-top: 1rem;
  background-color: var(--primary-color);
  width: fit-content;
  color: rgb(172, 6, 255);
  padding: 0.2rem;
  border-radius: 5;
}

.logo {
  font-family: 'Arial', sans-serif;
  font-size: 2rem;
  color: #3498db;
  animation: zoom 2s infinite alternate;
}

@keyframes zoom {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
}


.logo1 {
  font-family: 'Arial', sans-serif; /* Choose your preferred font */
  font-size: 2rem; /* Adjust the font size as needed */
  color:rgb(141, 2, 255); /* Choose your preferred text color */
  animation: bounce 1s infinite alternate;
}

 