 

  .compass {
    position: relative;
    width: 320px;
    height: 320px;
    border-radius: 50%;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0);
    margin: auto;
    background: "none";
  }
  
  .compass > .arrow {
    position: absolute;
    width: 0;
    height: 0;
    top: -0px;
    left: 50%;
    transform: translateX(-50%);
    border-style: solid;
    border-width: 20px 10px 0 10px;
    border-color: red transparent transparent transparent;
    background: "none";
    z-index: 1;
  }
  
  .compass > .compass-circle,
  .compass > .my-point {
    position: absolute;
    width: 18rem;
    height: 18rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: transform 0.1s ease-out;
    background: url("../public/assets/icons/laban22.png") center no-repeat; 
    background-size: contain;

  }
  
  .compass > .my-point {
    opacity: 0;
    width: 20%;
    height: 20%;
    background: "none";
    border-radius: 50%;
    transition: opacity 0.5s ease-out;
  }
  
 
 
 
 
.leaflet-control-locate {
  display: none;
}

 
 
.custom-tooltip {
  background: transparent;
  border: none;
  color: black;
    background-color: none;
    box-shadow: 0 0 0 0 !important;

}

.custom-control {
  position: absolute;
  border-radius: 30px;
  height: 2rem;
  width: 2rem;
  background: none;
  right: 0; /* Thêm đơn vị px */
  /* top: 355  */
  top: -40;  
}

 
/* CSS */
.leaflet-top {
  margin-top: 400;  
  transform: translateY(-50%);
  right: 30; /* Thêm đơn vị px */
  margin-right: 15;
}

.leaflet-control {
  margin: 0 auto;
}


.leaflet-tooltip {
  padding: 0;
  font-size: 0.6rem;
  font-weight: 600;
  width: 160px;
  white-space: normal;
  text-align: center;
  bottom: auto;
  top:37px;
  margin: -44px 0 0;
  color: rgb(255, 255, 255)!important;
  background:"rgba(255, 166, 0, 0)"!important;
  background : none!important;
  box-shadow: 0 0 0 0 !important;
  text-shadow: 0 0 1px #072CFA, 0 0 3px #072CFA, 0 0 5px #072CFA, 0 0 8px #072CFA, 0 0 8px #072CFA!important;
  border: none!important;
  display: -webkit-box;
  line-height: 19px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 38px;

  }
  .red-icon {
    color: red;
    border-radius: 30px;
    height: 2rem;
    width: 2rem;
  }
  
  
 


.leaflet-pm-hintline {
    pointer-events: none;
  }
  .leaflet-touch .leaflet-bar  {
    font-size: 30px !important;
    background-color: rgba(39, 32, 0, 0.582);
    background-image: url('https://img.upanh.tv/2022/09/23/header26859d4e3113dc229.jpg');
    background: "none";
    
                    }
  
                    .contact-button {
                      display: none;
                    }
                    
                    .contact-button.visible {
                      display: block;
                    }
                 
  
                    @media (max-width: 767px) { /* Mobile devices */
                      .image-container {
                          height: 100vh; /* Adjust the height for mobile */
                      }
                  
                      .text-overlay {
                          font-size: 16px; /* Adjust the font size for mobile */
                      }
                  }
                  
                  @media (min-width: 768px) { /* Desktop devices */
                      .image-container {
                          height: 100vh; /* Adjust the height for desktop */
                      }
                  
                      .text-overlay {
                          font-size: 24px; /* Adjust the font size for desktop */
                      }
                  }
                